import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`hello, this is a thing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const hello = "hello"

const sup = () => {
  return "one"
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/digitalhydra/pen/OJyXzwg"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/digitalhydra/embed/preview/OJyXzwg/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <p>{`and then that's it`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      